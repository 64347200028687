import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
import Pdf from "../components/documents/pdf"
import Seo from "../components/seo"

// Document
import privacyPdf from "../content/documents/tetra-privacy-statement.pdf"

// Styles
import { colors } from "../assets"

const PrivacyPolicyPage = ({ data }) => {
  const { privacyPolicy } = data.markdownRemark
    
  return (
    <Layout>
      <Container background={colors.TETRA_BLACK}>
        <Seo
          title={privacyPolicy.seoTitle}
          description={privacyPolicy.seoDescription}
          image={privacyPolicy.seoImage.publicURL}
        />

        <Pdf
          title={privacyPolicy.title}
          doc={privacyPdf}
        />
      </Container>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/privacy-policy" } }) {
      privacyPolicy: frontmatter {
        seoTitle
        seoDescription
        seoImage {
          publicURL
        }
        title
      }
    }
  }
`
